<template>
  <b-container v-if="!isLoading">
    <b-card class="p-1">
      <div class="m-5 p-5 font-large-1 text-center" v-if="orderData === null">
        <span>Order with ID</span>
        <br />
        <b>{{ orderID }}</b>
        <br />
        does not exist!
      </div>
      <div v-else class="my-2">
        <!-- Order Overview -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="ClipboardIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Order Overview</span>
            </div>
          </div>

          <div
            class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
          >
            <!-- Order ID -->
            <div class="d-flex justify-content-between my-1">
              <span class="h1 font-medium-1 text-primary">Order ID</span>
              <span class="font-medium-1 mr-1 text-right">{{ orderID }}</span>
            </div>

            <!-- Status -->
            <div class="d-flex justify-content-between my-1">
              <span class="h1 font-medium-1 text-primary">Status</span>
              <b-button
                v-if="orderData.status === 'Active'"
                variant="light-purple"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'Assigned'"
                variant="dark-purple"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'In Warehouse'"
                variant="light-blue"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'In Transit'"
                variant="yellow"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'Out For Delivery'"
                variant="purple"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'Delivered'"
                variant="turquoise"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button
                v-else-if="orderData.status === 'Second Delivery'"
                variant="orange"
                size="sm"
                pill
              >
                {{ orderData.status }}
              </b-button>
              <b-button v-else variant="orange" size="sm" pill>
                {{ orderData.status }}
              </b-button>
            </div>

            <!-- Shipment -->
            <div class="d-flex justify-content-between my-1">
              <span class="h1 font-medium-1 text-primary">Shipment</span>
              <b-button variant="gradient-danger" size="sm" pill>
                {{
                  orderData.order_type === "Outlet"
                    ? "Outlet"
                    : orderData.order_type
                }}
              </b-button>
            </div>

            <!-- Outlet ID -->
            <div class="d-flex justify-content-between my-1">
              <span class="h1 font-medium-1 text-primary">
                {{
                  orderData.order_type === "Outlet"
                    ? "Outlet"
                    : orderData.order_type
                }}
                ID</span
              >
              <span class="font-medium-1 mr-1 text-right">
                {{
                  orderData.order_type === "Distributor"
                    ? orderData.user.id
                    : !orderData.pickup
                    ? orderData.sender.id
                    : orderData.pickup.id
                }}
              </span>
            </div>

            <!-- Total Spent -->
            <div class="d-flex justify-content-between my-1">
              <span class="h1 font-medium-1 text-primary">Total Spent</span>
              <span class="font-medium-1 mr-1 text-right">
                {{ $helpers.formatTotal(orderData.amount) }}
              </span>
            </div>
          </div>
        </div>

        <!-- Date -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="CalendarIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Date</span>
            </div>
            <b-button
              v-if="!isDateEditable"
              variant="gradient-primary"
              @click="toggleEdit('date')"
              class="box-shadow-1"
              size="sm"
              pill
            >
              <feather-icon
                icon="EditIcon"
                size="15"
                color="white"
                class="mr-1"
              />
              Edit
            </b-button>
            <div v-else>
              <b-button
                variant="gradient-secondary"
                @click="toggleEdit('date')"
                class="box-shadow-1 mr-1"
                size="sm"
                pill
              >
                Cancel
              </b-button>
              <b-button
                :disabled="previousDate === orderData.delivery_date"
                variant="gradient-success"
                @click="updateDate()"
                class="box-shadow-1"
                size="sm"
                pill
              >
                Save
              </b-button>
            </div>
          </div>

          <div
            class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
          >
            <validation-observer ref="dateValidation">
              <!-- Order Date -->
              <div class="d-flex justify-content-between my-1">
                <span class="h1 font-medium-1 text-primary">Order Date</span>
                <span class="font-medium-1 mr-1 text-right">
                  {{
                    !orderData.order_placed_date
                      ? ""
                      : orderData.order_placed_date.substr(0, 10)
                  }}
                </span>
              </div>

              <!-- Delivery Date -->
              <div class="d-flex justify-content-between my-1">
                <span class="h1 font-medium-1 text-primary pt-1">
                  Delivery Date
                </span>
                <span
                  v-if="!isDateEditable"
                  class="font-medium-1 mr-1 text-right pt-1"
                >
                  {{
                    !orderData.delivery_date
                      ? ""
                      : orderData.delivery_date.substr(0, 10)
                  }}
                </span>
                <div v-else>
                  <validation-provider
                    #default="{ errors }"
                    name="Delivery Date"
                    rules="required"
                  >
                    <b-card no-body>
                      <flat-pickr
                        v-model="orderData.delivery_date"
                        class="form-control font-small-3 w-auto"
                        placeholder="Choose Date"
                        :config="{
                          dateFormat: 'Y-m-d',
                          minDate: orderData.order_placed_date,
                        }"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-card>
                  </validation-provider>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>

        <!-- Address -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="HomeIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Address</span>
            </div>

            <b-button
              v-if="!isAddressEditable"
              variant="gradient-primary"
              @click="toggleEdit('address')"
              class="box-shadow-1"
              size="sm"
              pill
            >
              <feather-icon
                icon="EditIcon"
                size="15"
                color="white"
                class="mr-1"
              />
              Edit
            </b-button>
            <div v-else>
              <b-button
                variant="gradient-secondary"
                @click="toggleEdit('address')"
                class="box-shadow-1 mr-1"
                size="sm"
                pill
              >
                Cancel
              </b-button>
              <b-button
                variant="gradient-success"
                @click="updateAddress()"
                class="box-shadow-1"
                size="sm"
                pill
              >
                Save
              </b-button>
            </div>
          </div>

          <validation-observer
            ref="addressValidation"
            class="row ml-sm-5 col-xs-12 col-sm-10 col-md-10 px-0 my-1"
          >
            <!-- Origin Address -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <span class="h1 font-medium-1 text-primary">
                Origin Address
              </span>
              <div
                class="w-100 border-primary rounded border-1 my-1 p-2 minimum-container-height"
              >
                <div v-if="!isAddressEditable">
                  <b class="h1 font-medium-3">
                    {{
                      orderData.sender.first_name +
                      (!orderData.sender.last_name
                        ? ""
                        : " " + orderData.sender.last_name)
                    }}
                  </b>
                  <br />
                  <div class="mt-1">
                    <span class="h1 font-medium-1">
                      {{ orderData.sender.complete_address }}
                    </span>
                  </div>
                  <div class="mt-3">
                    <span class="h1 font-medium-1">
                      {{ orderData.sender.phone }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender First Name"
                    rules="required"
                  >
                    <b-form-group label="First Name">
                      <input
                        v-model="orderData.sender.first_name"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender First Name"
                        aria-label="Sender First Name"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Last Name"
                    rules="required"
                  >
                    <b-form-group label="Last Name">
                      <input
                        v-model="orderData.sender.last_name"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Last Name"
                        aria-label="Sender Last Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Contact Number"
                    rules="required|positive"
                  >
                    <b-form-group label="Contact Number">
                      <input
                        v-model="orderData.sender.phone"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Contact Number"
                        aria-label="Sender Contact Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address Unit"
                    rules="required"
                  >
                    <b-form-group label="Address Unit">
                      <input
                        v-model="orderData.sender.address_unit"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Address Unit"
                        aria-label="Sender Address Unit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address Line 1"
                    rules="required"
                  >
                    <b-form-group label="Address Line 1">
                      <input
                        v-model="orderData.sender.address_line_1"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Address Line 1"
                        aria-label="Sender Address Line 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address Line 2"
                    rules="required"
                  >
                    <b-form-group label="Address Line 2">
                      <input
                        v-model="orderData.sender.address_line_2"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Address Line 2"
                        aria-label="Sender Address Line 2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    vid="sender_postcode"
                    name="Sender Address Postcode"
                  >
                    <b-form-group label="Postcode">
                      <input
                        v-model="orderData.sender.address_postcode"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Address Postcode"
                        aria-label="Sender Address Postcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address Area"
                  >
                    <b-form-group label="Area">
                      <input
                        v-model="orderData.sender.address_area"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Sender Address Area"
                        aria-label="Sender Address Area"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address State"
                  >
                    <b-form-group label="State">
                      <select
                        class="form-control form-select rounded mr-2"
                        v-model="orderData.sender.address_state"
                      >
                        <option value="" disabled selected>State</option>
                        <option v-for="(state, index) in state" :key="index">
                          {{ state.state_name }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Address Country"
                  >
                    <b-form-group label="Country">
                      <select
                        class="form-control form-select rounded"
                        v-model="orderData.sender.address_country"
                      >
                        <option value="" disabled selected>Country</option>
                        <option
                          v-for="(country, index) in country"
                          :key="index"
                        >
                          {{ country.value }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- Destination Address -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <span class="h1 font-medium-1 text-primary">
                Destination Address
              </span>
              <div
                class="w-100 border-primary rounded border-1 my-1 p-2 minimum-container-height"
              >
                <div v-if="!isAddressEditable">
                  <b class="h1 font-medium-3">
                    {{
                      orderData.recipient.first_name +
                      (!orderData.recipient.last_name
                        ? ""
                        : " " + orderData.recipient.last_name)
                    }}
                  </b>
                  <br />
                  <div class="mt-1">
                    <span class="h1 font-medium-1">
                      {{ orderData.recipient.complete_address }}
                    </span>
                  </div>
                  <div class="mt-3">
                    <span class="h1 font-medium-1">
                      {{ orderData.recipient.phone }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient First Name"
                    rules="required"
                  >
                    <b-form-group label="First Name">
                      <input
                        v-model="orderData.recipient.first_name"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient First Name"
                        aria-label="Recipient First Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Last Name"
                    rules="required"
                  >
                    <b-form-group label="Last Name">
                      <input
                        v-model="orderData.recipient.last_name"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Last Name"
                        aria-label="Recipient Last Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Contact Number"
                    rules="required|positive"
                  >
                    <b-form-group label="Contact Number">
                      <input
                        v-model="orderData.recipient.phone"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Contact Number"
                        aria-label="Recipient Contact Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address Unit"
                    rules="required"
                  >
                    <b-form-group label="Address Unit">
                      <input
                        v-model="orderData.recipient.address_unit"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Address Unit"
                        aria-label="Recipient Address Unit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address Line 1"
                    rules="required"
                  >
                    <b-form-group label="Address Line 1">
                      <input
                        v-model="orderData.recipient.address_line_1"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Address Line 1"
                        aria-label="Recipient Address Line 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address Line 2"
                    rules="required"
                  >
                    <b-form-group label="Address Line 2">
                      <input
                        v-model="orderData.recipient.address_line_2"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Address Line 2"
                        aria-label="Recipient Address Line 2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    vid="recipient_postcode"
                    name="Recipient Address Postcode"
                  >
                    <b-form-group label="Postcode">
                      <input
                        v-model="orderData.recipient.address_postcode"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Address Postcode"
                        aria-label="Recipient Address Postcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address Area"
                  >
                    <b-form-group label="Area">
                      <input
                        v-model="orderData.recipient.address_area"
                        type="text"
                        class="form-control font-small-3"
                        placeholder="Recipient Address Area"
                        aria-label="Recipient Address Area"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address State"
                  >
                    <b-form-group label="State">
                      <select
                        class="form-control form-select rounded mr-2"
                        v-model="orderData.recipient.address_state"
                      >
                        <option value="" disabled selected>State</option>
                        <option v-for="(state, index) in state" :key="index">
                          {{ state.state_name }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient Address Country"
                  >
                    <b-form-group label="Country">
                      <select
                        class="form-control form-select rounded"
                        v-model="orderData.recipient.address_country"
                      >
                        <option value="" disabled selected>Country</option>
                        <option
                          v-for="(country, index) in country"
                          :key="index"
                        >
                          {{ country.value }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>

        <!-- Product Details -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="BoxIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Product Details</span>
            </div>
            <div v-if="this.orderData.order_type != 'Ondemand'">
              <b-button
                v-if="!isProductEditable"
                variant="gradient-primary"
                @click="toggleEdit('product')"
                class="box-shadow-1"
                size="sm"
                pill
              >
                <feather-icon
                  icon="EditIcon"
                  size="15"
                  color="white"
                  class="mr-1"
                />
                Edit
              </b-button>
              <div v-else>
                <b-button
                  variant="gradient-secondary"
                  @click="toggleEdit('product')"
                  class="box-shadow-1 mr-1"
                  size="sm"
                  pill
                >
                  Cancel
                </b-button>
                <b-button
                  variant="gradient-success"
                  @click="updateProduct()"
                  class="box-shadow-1"
                  size="sm"
                  pill
                >
                  Save
                </b-button>
              </div>
            </div>
          </div>
          <b-card no-body v-if="this.orderData.order_type == 'Ondemand'">
            <div class="ml-sm-5 col-xs-12 p-0 my-1 p-sm-3 rounded">
              <vue-good-table
                :columns="Ondemandcolumns"
                :rows="rows"
                styleClass="vgt-table border font-small-3"
                :sort-options="{
                  enabled: false,
                }"
              >
                <template slot="table-column" slot-scope="props">
                  <div class="m-2">
                    {{ props.column.label }}
                  </div>
                </template>
              </vue-good-table>
            </div>
          </b-card>
          <b-card no-body v-else>
            <div
              class="ml-sm-5 col-xs-12 p-0 my-1 p-sm-3 rounded"
              v-if="!isProductEditable"
            >
              <vue-good-table
                :columns="columns"
                :rows="rows"
                styleClass="vgt-table border font-small-3"
                :sort-options="{
                  enabled: false,
                }"
              >
                <template slot="table-column" slot-scope="props">
                  <div class="m-2">
                    {{ props.column.label }}
                  </div>
                </template>
              </vue-good-table>
            </div>
            <div v-else-if="this.orderData.order_type == 'Distributor'">
              <validation-observer ref="productValidation">
                <validation-provider
                  #default="{ errors }"
                  name="Product Quantity"
                  rules="required"
                >
                  <div class="ml-sm-5 col-xs-12 p-0 my-1 p-sm-3 rounded">
                    <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      styleClass="vgt-table border font-small-3"
                      :sort-options="{
                        enabled: false,
                      }"
                    >
                      <template slot="table-column" slot-scope="props">
                        <div class="m-2">
                          {{ props.column.label }}
                        </div>
                      </template>
                      <template
                        slot="table-row"
                        slot-scope="props"
                        class="d-flex align-items-center bg-primary"
                      >
                        <div v-if="props.column.field === 'qty'">
                          <b-form-spinbutton
                            v-model="newQuantity"
                            min="1"
                            id="sb-input"
                            wrap
                          ></b-form-spinbutton>
                        </div>
                      </template>
                    </vue-good-table>
                  </div>
                </validation-provider>
              </validation-observer>
            </div>
            <div v-else>
              <validation-observer ref="productValidation">
                <validation-provider
                  #default="{ errors }"
                  name="Product Quantity"
                  rules="required"
                >
                  <div class="ml-sm-5 col-xs-12 p-0 my-1 p-sm-3 rounded">
                    <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      styleClass="vgt-table border font-small-3"
                      :sort-options="{
                        enabled: false,
                      }"
                    >
                      <template slot="table-column" slot-scope="props">
                        <div class="m-2">
                          {{ props.column.label }}
                        </div>
                      </template>
                      <template
                        slot="table-row"
                        slot-scope="props"
                        class="d-flex align-items-center bg-primary"
                      >
                        <div v-if="props.column.field === 'qty'">
                          <b-form-spinbutton
                            v-model="newQuantity"
                            min="1"
                            id="sb-input"
                            wrap
                          ></b-form-spinbutton>
                        </div>
                      </template>
                    </vue-good-table>
                  </div>
                </validation-provider>
              </validation-observer>
            </div>
          </b-card>
        </div>

        <!-- Download Label -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="TagIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Delivery Order Label</span>
            </div>
          </div>

          <div
            class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
          >
            <b-button
              class="w-100 font-small-3 my-2"
              variant="gradient-primary"
              @click="generateLabel"
              pill
            >
              DOWNLOAD LABEL
            </b-button>
          </div>
        </div>

        <!-- Download Invoice  -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="CastIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Invoice</span>
            </div>
          </div>

          <div
            class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
          >
            <b-button
              class="w-100 font-small-3 my-2"
              variant="gradient-primary"
              @click="downloadInvoice"
              pill
            >
              DOWNLOAD INVOICE
            </b-button>
          </div>
        </div>

        <!-- Delivery Details  -->
        <div class="w-100 mb-5">
          <validation-observer ref="remarksValidation">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <div
                  class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
                >
                  <feather-icon icon="TruckIcon" size="24" />
                </div>
                <span class="h1 font-medium-3 m-0">Delivery Details</span>
              </div>

              <b-button
                v-if="!isRemarksEditable"
                variant="gradient-primary"
                @click="toggleEdit('remarks')"
                class="box-shadow-1"
                size="sm"
                pill
              >
                <feather-icon
                  icon="EditIcon"
                  size="15"
                  color="white"
                  class="mr-1"
                />
                Edit
              </b-button>
              <div v-else>
                <b-button
                  variant="gradient-secondary"
                  @click="toggleEdit('remarks')"
                  class="box-shadow-1 mr-1"
                  size="sm"
                  pill
                >
                  Cancel
                </b-button>
                <b-button
                  :disabled="
                    !orderData.remarks || previousRemarks === orderData.remarks
                  "
                  variant="gradient-success"
                  @click="updateRemarks()"
                  class="box-shadow-1"
                  size="sm"
                  pill
                >
                  Save
                </b-button>
              </div>
            </div>
            <div
              class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-6 p-0"
            >
              <!-- Assigned Driver -->
              <div class="d-flex justify-content-between my-1">
                <span class="h1 font-medium-1 text-primary"
                  >Assigned Driver</span
                >
                <span class="font-medium-1 mr-1 text-right">
                  {{
                    orderData.driver !== null
                      ? orderData.driver.name
                      : "No Driver Assigned"
                  }}
                </span>
              </div>

              <!-- Remarks -->
              <div class="d-flex justify-content-between my-1">
                <span class="h1 font-medium-1 text-primary pt-1">
                  Remarks
                </span>
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                  class="w-50"
                >
                  <input
                    v-model="orderData.remarks"
                    :disabled="!isRemarksEditable"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </div>

        <!-- Proof of Delivery -->
        <div class="w-100 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon-bg text-white d-none d-sm-block justify-content-center align-items-center mr-1"
              >
                <feather-icon icon="HomeIcon" size="24" />
              </div>
              <span class="h1 font-medium-3 m-0">Proof of Delivery</span>
            </div>

            <b-button
              v-if="!isImageEditable"
              variant="gradient-primary"
              @click="toggleEdit('image')"
              class="box-shadow-1"
              size="sm"
              pill
            >
              <feather-icon
                icon="EditIcon"
                size="15"
                color="white"
                class="mr-1"
              />
              Edit
            </b-button>
            <div v-else>
              <b-button
                variant="gradient-secondary"
                @click="toggleEdit('image')"
                class="box-shadow-1 mr-1"
                size="sm"
                pill
              >
                Cancel
              </b-button>
              <b-button
                :disabled="
                  this.pickUpPayload === this.dropOffPayload ? true : false
                "
                variant="gradient-success"
                @click="updateImage()"
                class="box-shadow-1"
                size="sm"
                pill
              >
                Save
              </b-button>
            </div>
          </div>

          <div class="row ml-sm-5 col-xs-12 col-sm-10 col-md-10 px-0 my-1">
            <!-- Pick-Up Photo -->
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1 p-2"
            >
              <span class="h1 font-medium-1 text-primary"> Pick-Up Photo </span>
              <b-card no-body>
                <div
                  class="mt-1 mx-0 p-1 rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                >
                  <ImageUpload
                    label="pickUp"
                    :isImageEditable="isImageEditable"
                    :img="this.orderData.pick_up_photo"
                    :baseURL="baseURL"
                    @renderImage="renderImage"
                  />
                </div>
              </b-card>
            </div>

            <!-- Drop-Off Photo -->
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1 p-2"
            >
              <span class="h1 font-medium-1 text-primary">
                Drop-Off Photo
              </span>
              <b-card no-body>
                <div
                  class="mt-1 mx-0 p-1 rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                >
                  <ImageUpload
                    label="dropOff"
                    :isImageEditable="isImageEditable"
                    :img="this.orderData.drop_off_photo"
                    :baseURL="baseURL"
                    @renderImage="renderImage"
                  />
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button
        variant="gradient-primary"
        class="box-shadow-1 px-3 mx-2"
        @click="back"
      >
        Back
      </b-button>
    </div>

    <div v-if="downloadInvoiceD" hidden>
      <Invoice :order="orderData" />
    </div>
  </b-container>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ImageUpload from "../../../components/ImageUpload.vue";
import Invoice from "@/views/components/Invoice.vue";

export default {
  components: {
    flatPickr,
    extend,
    ImageUpload,
    Invoice,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
  },

  mounted() {
    this.getOrder();
    this.$http.get(`state`).then((response) => {
      this.state = response.data.data;
    });
  },

  methods: {
    back() {
      this.$router.back();
    },
    getOrder() {
      this.rows = [];
      this.$http.get(`get_order/${this.orderID}`).then((response) => {
        if (Object.keys(response.data.data).length > 1) {
          this.orderData = response.data.data;
          this.previousDate = this.orderData.delivery_date;
          this.previousRemarks = this.orderData.remarks;
          this.newQuantity = this.orderData.quantity;
          if (!this.orderData.recipient.last_name) {
            const names = this.orderData.recipient.first_name.split(" ");
            this.orderData.recipient.first_name = names[0];
            this.orderData.recipient.last_name = names.slice(1).join(" ");
          }
          this.rows.push({
            prodCategory: this.orderData.product_category,
            packaging: this.orderData.packaging,
            qty: this.orderData.quantity,
            size: this.orderData.item_size,
            weight: this.orderData.item_weight,
            sku: this.orderData.sku,
            temp: this.orderData.temperature,
            remarks: this.orderData.remarks,
          });
        }
        this.isLoading = false;
      });
    },

    generateLabel() {
      window.open(
        `${this.$baseURL}/generate_golog_label/${this.orderID}`,
        "_blank"
      );
    },

    downloadInvoice() {
      this.downloadInvoiceD = true;
    },

    toggleEdit(value) {
      switch (value) {
        case "date":
          this.isDateEditable = !this.isDateEditable;
          break;
        case "address":
          this.isAddressEditable = !this.isAddressEditable;
          break;
        case "remarks":
          this.isRemarksEditable = !this.isRemarksEditable;
          break;
        case "image":
          this.isImageEditable = !this.isImageEditable;
          break;
        case "product":
          this.isProductEditable = !this.isProductEditable;
          break;
      }
    },

    updateProduct() {
      this.payload = {
        quantity: this.newQuantity,
      };
      this.$http
        .post(`order-quantity-update/${this.orderData.id}`, this.payload)
        .then((response) => {
          if (response.data.status) {
            this.$swal({
              icon: "success",
              title: "Success !",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            this.getOrder();
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
      this.isProductEditable = false;
    },

    updateDate() {
      this.$refs.dateValidation.validate().then((success) => {
        if (success) {
          this.payload = {
            order_id: this.orderID,
            delivery_date: this.orderData.delivery_date,
            remarks: this.orderData.remarks,
            sender_location_id: this.orderData.sender.id,
            sender_first_name: this.orderData.sender.first_name,
            sender_last_name: this.orderData.sender.last_name,
            sender_phone_number: this.orderData.sender.phone,
            sender_unit_number: this.orderData.sender.address_unit,
            sender_address_line_1: this.orderData.sender.address_line_1,
            sender_address_line_2: this.orderData.sender.address_line_2,
            sender_postcode: this.orderData.sender.address_postcode,
            sender_area: this.orderData.sender.address_area,
            sender_state: this.orderData.sender.address_state,
            sender_country: this.orderData.sender.address_country,
            recipient_location_id: this.orderData.recipient.id,
            recipient_first_name: this.orderData.recipient.first_name,
            recipient_last_name: this.orderData.recipient.last_name,
            recipient_phone_number: this.orderData.recipient.phone,
            recipient_unit_number: this.orderData.recipient.address_unit,
            recipient_address_line_1: this.orderData.recipient.address_line_1,
            recipient_address_line_2: this.orderData.recipient.address_line_2,
            recipient_postcode: this.orderData.recipient.address_postcode,
            recipient_area: this.orderData.recipient.address_area,
            recipient_state: this.orderData.recipient.address_state,
            recipient_country: this.orderData.recipient.address_country,
          };
          this.$http
            .post(`update_order_address`, this.payload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  icon: "success",
                  title: "Success !",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                });
              }
            });
          this.previousDate = this.orderData.delivery_date;
          this.isDateEditable = false;
        }
      });
    },

    updateAddress() {
      this.$refs.addressValidation.validate().then((success) => {
        if (success) {
          this.payload = {
            order_id: this.orderID,
            delivery_date: this.orderData.delivery_date,
            remarks: this.orderData.remarks ? this.orderData.remarks : "None",
            sender_location_id: this.orderData.sender.id,
            sender_first_name: this.orderData.sender.first_name,
            sender_last_name: this.orderData.sender.last_name,
            sender_phone_number: this.orderData.sender.phone,
            sender_unit_number: this.orderData.sender.address_unit,
            sender_address_line_1: this.orderData.sender.address_line_1,
            sender_address_line_2: this.orderData.sender.address_line_2,
            sender_postcode: this.orderData.sender.address_postcode,
            sender_area: this.orderData.sender.address_area,
            sender_state: this.orderData.sender.address_state,
            sender_country: this.orderData.sender.address_country,
            recipient_location_id: this.orderData.recipient.id,
            recipient_first_name: this.orderData.recipient.first_name,
            recipient_last_name: this.orderData.recipient.last_name,
            recipient_phone_number: this.orderData.recipient.phone,
            recipient_unit_number: this.orderData.recipient.address_unit,
            recipient_address_line_1: this.orderData.recipient.address_line_1,
            recipient_address_line_2: this.orderData.recipient.address_line_2,
            recipient_postcode: this.orderData.recipient.address_postcode,
            recipient_area: this.orderData.recipient.address_area,
            recipient_state: this.orderData.recipient.address_state,
            recipient_country: this.orderData.recipient.address_country,
          };
          this.$http
            .post(`update_order_address`, this.payload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  icon: "success",
                  title: "Success !",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.isAddressEditable = false;
              } else if (response.data.errors != null) {
                this.$refs.addressValidation.setErrors(response.data.errors);
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                });
              }
            });
        }
      });
    },

    updateRemarks() {
      this.$refs.remarksValidation.validate().then((success) => {
        if (success) {
          this.payload = {
            order_id: this.orderID,
            delivery_date: this.orderData.delivery_date,
            remarks: this.orderData.remarks,
            sender_location_id: this.orderData.sender.id,
            sender_first_name: this.orderData.sender.first_name,
            sender_last_name: this.orderData.sender.last_name,
            sender_phone_number: this.orderData.sender.phone,
            sender_unit_number: this.orderData.sender.address_unit,
            sender_address_line_1: this.orderData.sender.address_line_1,
            sender_address_line_2: this.orderData.sender.address_line_2,
            sender_postcode: this.orderData.sender.address_postcode,
            sender_area: this.orderData.sender.address_area,
            sender_state: this.orderData.sender.address_state,
            sender_country: this.orderData.sender.address_country,
            recipient_location_id: this.orderData.recipient.id,
            recipient_first_name: this.orderData.recipient.first_name,
            recipient_last_name: this.orderData.recipient.last_name,
            recipient_phone_number: this.orderData.recipient.phone,
            recipient_unit_number: this.orderData.recipient.address_unit,
            recipient_address_line_1: this.orderData.recipient.address_line_1,
            recipient_address_line_2: this.orderData.recipient.address_line_2,
            recipient_postcode: this.orderData.recipient.address_postcode,
            recipient_area: this.orderData.recipient.address_area,
            recipient_state: this.orderData.recipient.address_state,
            recipient_country: this.orderData.recipient.address_country,
          };
          this.$http
            .post(`update_order_address`, this.payload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  icon: "success",
                  title: "Success !",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                });
              }
            });
          this.previousRemarks = this.orderData.remarks;
          this.isRemarksEditable = false;
        }
      });
    },

    // Upload PickUp and DropOff photos
    async updateImage() {
      this.isImageEditable = false;
      let message = "Photo has been uploaded";
      if (this.pickUpPayload !== null) {
        const pickUpForm = new FormData();
        pickUpForm.append("delivery_image", this.pickUpPayload.delivery_image);
        pickUpForm.append("order_id", this.pickUpPayload.order_id);
        pickUpForm.append("type", this.pickUpPayload.type);

        await this.$http
          .post(`upload_delivery_photo`, pickUpForm)
          .then((response) => {
            this.pickUpPayload = null;
            if (!response.data.status) {
              message = response.data.message;
            }
          });
      }
      if (this.dropOffPayload !== null) {
        const dropOffForm = new FormData();
        dropOffForm.append(
          "delivery_image",
          this.dropOffPayload.delivery_image
        );
        dropOffForm.append("order_id", this.dropOffPayload.order_id);
        dropOffForm.append("type", this.dropOffPayload.type);
        await this.$http
          .post(`upload_delivery_photo`, dropOffForm)
          .then((response) => {
            this.dropOffPayload = null;
            if (!response.data.status) {
              message = response.data.message;
            }
          });
      }

      if (message === "Photo has been uploaded") {
        this.$swal({
          icon: "success",
          title: "Success !",
          text: message,
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    },

    renderImage(image, label) {
      if (label === "pickUp") {
        this.pickUpPayload = {
          delivery_image: image,
          order_id: this.orderID,
          type: "Pick-up",
        };
      } else {
        this.dropOffPayload = {
          delivery_image: image,
          order_id: this.orderID,
          type: "Drop-off",
        };
      }
    },
  },

  data() {
    return {
      downloadInvoiceD: false,
      isLoading: true,
      isDateEditable: false,
      isAddressEditable: false,
      isRemarksEditable: false,
      isImageEditable: false,
      isProductEditable: false,
      payload: {},
      previousDate: null,
      previousRemarks: null,
      orderID: this.$route.params.id,
      orderData: null,
      pickUpPayload: null,
      dropOffPayload: null,
      columns: [
        {
          label: "Product Categories",
          field: "prodCategory",
          tdClass: "py-5 text-center",
        },
        {
          label: "Packaging",
          field: "packaging",
          tdClass: "py-5 text-center",
        },
        {
          label: "QTY",
          field: "qty",
          tdClass: "text-center align-middle",
        },
        {
          label: "Size",
          field: "size",
          tdClass: "py-5 text-center",
        },
        {
          label: "Weight (kg)",
          field: "weight",
          tdClass: "py-5 text-center",
        },
        {
          label: "SKU",
          field: "sku",
          tdClass: "py-5 text-center",
        },
        {
          label: "Temperature",
          field: "temp",
          tdClass: "py-5 text-center",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "py-5 text-center",
        },
      ],
      Ondemandcolumns: [
        {
          label: "Product Categories",
          field: "prodCategory",
          tdClass: "py-5 text-center",
        },
        {
          label: "Packaging",
          field: "packaging",
          tdClass: "py-5 text-center",
        },
        {
          label: "Weight (kg)",
          field: "weight",
          tdClass: "py-5 text-center",
        },
        {
          label: "SKU",
          field: "sku",
          tdClass: "py-5 text-center",
        },
        {
          label: "Temperature",
          field: "temp",
          tdClass: "py-5 text-center",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "py-5 text-center",
        },
      ],
      rows: [],
      required,
      baseURL: `${this.$s3URL}/minidc/delivery_photo/`,
      state: [],
      country: [
        {
          title: "Malaysia",
          value: "Malaysia",
        },
        {
          title: "Singapore",
          value: "Singapore",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.icon-bg {
  background-color: #0f4392;
  height: 40px;
  width: 40px;
  margin-top: -10px;
  padding-top: 8px;
  padding-left: 8px;
}

.icon {
  top: 20%;
  left: 20%;
}

.fixed-width-container {
  height: 330px;
}

.minimum-container-height {
  min-height: 210px;
}

.border {
  border-width: 3px !important;
}
</style>
